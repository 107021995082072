import './report-card.scss';
import { Button, Card, CardMain, Spinner } from '@o4c/plugin-components';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import fileSaver from 'file-saver';
import { Fragment, useState } from 'react';
import { Errors } from '../../../shared/components/errors/errors';

export interface IReportCardProps {
  date: Dayjs;
  primary: boolean;
  driverId: string;
}

export const ReportCard = (props: IReportCardProps) => {
  const { t } = useTranslation();
  let location = useLocation();
  let query = new URLSearchParams(location.search);
  const token = query.get("token") || "";
  const [error, setError] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  
  const openReport = async (year: string, week: string) => {
    const url = `/api/reports/${year}/${week}`;
    const headers = {
      'Content-Type': 'application/pdf',
      'Authorization': token,
    };
    setIsDownloading(true);
    await axios(url, { headers: headers, responseType: 'arraybuffer' }).then((result) => {
      var blob = new Blob([result.data], { type: 'application/pdf' });
      fileSaver.saveAs(blob, `report_${year}_${week}.pdf`);
      setIsDownloading(false);
    }).catch((error) => {
      console.log(error);
      setError(true);
      setIsDownloading(false);
    });
  };

  return <Fragment>
    {error ?
      <Errors
        title={t('reports.downloadErrorTitle')}
        text={t('reports.downloadErrorText')}
        action={() => setError(false)}
        actionText={t('reports.close')} />
      : null}
    <Card>
      <CardMain>
        <div className="report">
          <div className="overview">
            <div className="week">Week {dayjs(props.date).format("WW")}</div>
            <span>{dayjs(props.date).day(1).format(t('dateFormatting.reportDetails'))}</span><br />
            <span>{dayjs(props.date).day(7).format(t('dateFormatting.reportDetails'))}</span>
          </div>
          <Button
            onClick={() => openReport(dayjs(props.date).format("YYYY"), dayjs(props.date).format("WW"))}
            color={props.primary ? 'primary' : 'default'}
            type={props.primary ? 'solid' : 'outline'}
            display="inline">
            {isDownloading ? <Spinner type='circle' color='primary'/> : t('reports.download')}
          </Button>
        </div>
      </CardMain>
    </Card>
  </Fragment>
}

