import './reports.scss';
import { Icon, Timeline, TimelineItem, TimelineItemTitle, Typography } from '@o4c/plugin-components';
import { ReportCard } from './report-card/report-card';
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";

interface IReportsProps {
  driverId: string;
}

export const Reports = (props: IReportsProps) => {
  const { t } = useTranslation();
  
  const getDates = () => {
    const dates = [];

    //latest report is for the previous week so we start counting from last week
    let date = dayjs().subtract(1, 'week'); 
    const totalDates = 5;
    for (let i = 1; i <= totalDates; i++) {
      dates.push(date)
      date = date.subtract(1, 'week')
    }

    return dates
  }

  const dates = getDates();

  return <Timeline>
    <TimelineItem className="primary-report" color="primary">
      <TimelineItemTitle>
        <Typography type="title-header" color="primary">{t('reports.latestReport')}</Typography>
      </TimelineItemTitle>
      <ReportCard date={dates[0]} driverId={props.driverId}
        primary={true} />
    </TimelineItem>
    <TimelineItem color="primary">
      <TimelineItemTitle>
        {t('reports.previousReports')}
      </TimelineItemTitle>
      {
        dates.slice(1).map((date, index) => {
          return <ReportCard key={index} date={date} driverId={props.driverId}
            primary={false} />
        })
      }
      <div className="comment">
        <Icon size='medium' name="find-shift" leading />
        <span className="caption-text">{t('reports.comment')}</span>
      </div>
    </TimelineItem>
  </Timeline>
}

