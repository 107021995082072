import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './services/i18n';
import '@o4c/css/foundation.scss';
import { ProvideRollbar } from './providers/provide-rollbar';
import dayjs from 'dayjs';
import IsoWeek from 'dayjs/plugin/isoWeek'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(advancedFormat)
dayjs.extend(IsoWeek)

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ProvideRollbar>
        <Suspense fallback={<div>Loading...</div>}>
          <App />
        </Suspense>
      </ProvideRollbar>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
