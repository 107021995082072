import { GlobalTheme } from '@o4c/plugin-components';
import { Reports } from './views/reports/reports';
import { useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { useEffect, useState } from 'react';
import { Errors } from './shared/components/errors/errors';
import { useTranslation } from 'react-i18next';

const dhlTheme = {
  primaryR: 212,
  primaryG: 5,
  primaryB: 17,
  primary: 'rgba(var(--primary-r), var(--primary-g), var(--primary-b), 1)'
};

const App = () => {
  const { t } = useTranslation();
  let location = useLocation();
  const [error, setError] = useState<boolean>(false)
  const [driverId, setDriverId] = useState<string>('')
  let query = new URLSearchParams(location.search);
  const token = query.get("token") || "";

  useEffect(() => {
    if (token) {
      try {
        const { driverId }: any = jwt_decode(token) || ''
        setDriverId(driverId)
      } catch {
        setError(true)
      }
    } else {
      setError(true)
    }
  }, [token])

  return <>
    <GlobalTheme overrides={dhlTheme} />
    {error ? <Errors title={t('errors.title')} text={t('errors.text')} /> :
      <Reports driverId={driverId} />
    }
  </>
}

export default App;
