import { Button } from '@o4c/plugin-components';
import { useTranslation } from 'react-i18next';
import './errors.scss';

interface IErrorsProps {
  title: string;
  text: string;
  action?: Function;
  actionText?: string;
}

export function Errors({ title, text, action, actionText }: IErrorsProps) {
  const { t } = useTranslation();

  return <>

    <div className="errors-container">
      <div className="o4c card errors">
        <div className="header">
          <div><i className="icon-alert leading" /> {t('errors.header')}</div>
        </div>
        <div className="main">
          <p><strong>{title}</strong></p>
          <p>{text}</p>
          {action ?
          <Button type="outline" display="inline" onClick={action}>{actionText}</Button>
          : null}
        </div>
      </div>
    </div>

  </>
}